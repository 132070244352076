@import './themeStyles';
@import './commonStyles';

* {
    font-family: $theme-font;
}

body {
    background-color: $page-background-color;
    width: 100%;
}

#root {
    width: 100%;
    @include flex-center-column();
}

.App {
    @include flex-center-column();
    width: 100%;
}

.Gallery {
    @include flex-center-column();
    width: 100%;
}