@import 'commonStyles';
@import 'themeStyles';


.result-gallery-component {
    // &, * {
    //         border: $dev-border;
    // }

    width: 100%;

    @include flex-center-column;
    position: relative;

    .image-container {
        width: 100%;
        padding: 2.5%;
        padding-top: 100px;

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: 25vh;
        gap: 25px;
        overflow: hidden;
        position: relative;
        align-items: center;
        justify-items: center;

        .result-card {
            height: 100%;
            width: 100%;
            @include flex-center-column;
            background-color: black;
            border: 2px solid $box-border-color;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: $box-shadows;
            animation: popup-resultCard 500ms forwards;

            &:hover {
                cursor: pointer;
                transform: scale(102%, 102%);
                border-color: $blue-back-color;
            }

            .result-image {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        @keyframes popup-resultCard {
            0% {
                height: 80%;
                width: 80%;
                opacity: 0;
            }

            100% {
                height: 100%;
                width: 100%;
                opacity: 100%;
            }
        }
    }
}

@media screen and (max-width: 1620px) {
    .result-gallery-component {
        .image-container {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}


@media screen and (max-width: $tablet-bp) {
    .result-gallery-component {
        margin-top: 30px;

        .image-container {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media screen and (max-width: $mobile-bp) {
    .result-gallery-component {
        margin-top: 30px;

        .image-container {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}