@use "sass:color";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

$navbar-color: rgb(255, 237, 213);

$page-background-color: #ffffff;

$box-border-color: #e7e7e7;

$header-background-color: rgba(211, 180, 136, 0.5); // #D3B488 50%

$footer-background-color: $header-background-color;

$header-border-color: rgb(209, 190, 163); // D1BEA3

$footer-border-color: $header-border-color;

$theme-font: 'Montserrat', sans-serif;

$title-margin: 0 0 25px 0;

$checked-star-color: #E7B507;

$unchecked-star-color: #E7E7E7;

$gradient-dark-color: rgba(231, 231, 231, 0);

$box-shadows: 5px 5px 10px 0 rgba(0, 0, 0, 0.4), 0 0 15px 0 rgba(0, 0, 0, 0.25);

$button-border-color: #696969;

$button-background-color: $box-border-color;

$title-font-size: 2.5em;
$mobile-title-font-size: 35px;

$box-gaps: 25px;