@use "sass:color";
@import 'commonStyles';
@import 'themeStyles';

.footer-component {

    width: 95%;
    @include flex-center-row();
    background-image: linear-gradient(174.2deg, rgba(255, 244, 228, 1) 7.1%, rgba(240, 246, 238, 1) 67.4%);
    border-radius: 10px 10px 0 0;
    border: $box-border-color;
    box-shadow: $box-shadows;
    margin-top: $box-gaps 0;

    .navbar {
        // border: $dev-border;
        width: 30%;
        margin: 20px;

        .site-logo {
            @include flex-center-row();

            .logo {
                margin: 10px;
                width: 80%;
            }
        }

        .nav-links {
            @include flex-column();

            margin: 10px;
            margin-top: 20px;

            button {
                text-decoration: none;
                border: none;
                background-color: transparent;
                line-height: 2em;
                font-size: 20px;

                &:hover {
                    background-color: rgba(129, 129, 129, 0.11);
                    cursor: pointer;
                }
            }
        }
    }

    .contact-component {
        width: 40%;
    }
}

@media screen and (max-width: $desktop-bp) and (min-width : $mobile-bp) {
    .footer-component {
        // flex-direction: column-reverse;

        .contact-component {
            width: 60%;
        }

        .navbar {
            width: 50%;
        }
    }
}


@media screen and (max-width: $tablet-bp) {
    .footer-component {
        .contact-component {
            margin-top: 20px;

            .contact-container {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: $mobile-bp) {
    .footer-component {

        width: 100vw;

        display: flex;
        flex-direction: column-reverse;

        .contact-component {
            width: 100%;

            .contact-view-component {
                width: 95%;
                margin: auto;
                padding: 0;

                .component-title {
                    font-size: $mobile-title-font-size;
                    width: 100%;
                    padding-bottom: 10px;
                }
            }
        }

        .navbar {
            width: 100%;

            .site-logo {
                width: auto;
                @include flex-center-column;
            }

            .nav-links {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                background-color: color.adjust(color.adjust($navbar-color, $alpha: -0.65), $whiteness: 20%);

                * {
                    @include flex-center-column;
                    height: 2em;
                }
            }
        }
    }
}