@use "sass:color";
@import 'commonStyles';
@import 'themeStyles';



.contact-view-component {
    // border: $dev-border;
    width: 100%;
    @include flex-center-column();

    .icon {
        color: color.adjust($blue-back-color, $whiteness: 75%);
        font-size: 2.5em;
        stroke: $blue-back-color;
        stroke-width: 3px;
    }

    .component-title {
        text-align: center;
        font-size: 3em;
    }

    .contact-container {
        padding: 20px;
        width: 90%;

        .contact-card {
            background: $blue-back-color;
            box-shadow: $box-shadows;
            border-radius: 20px;
            margin-bottom: 20px;
            width: 100%;
            height: 100%;

            .branch-name {
                width: 100%;
                font-size: 35px;
                color: white;
                padding: 10px 0;
                text-align: center;
            }

            .details {
                background: color.adjust($blue-back-color, $whiteness: 80%);
                border-radius: 0px 0px 20px 20px;
                @include flex-column();
                padding: 10px 0;

                .detail-box {
                    // border: $dev-border;
                    @include flex-row;
                    padding: 10px 30px 5px 30px;

                    $icon-size: 40px;

                    .icon-div {
                        // border: $dev-border;
                        height: $icon-size;
                        width: $icon-size;
                        align-self: flex-start;

                        &:hover {
                            cursor: pointer;
                        }

                        img {
                            object-fit: cover;
                            height: 100%;
                            width: auto;
                        }
                    }

                    .text {
                        // border: 2px solid black;
                        // border: $dev-border;
                        height: 100%;
                        width: 100%;
                        text-align: justify;
                        padding: 8px 5px 0 20px;
                        font-size: large;
                        cursor: default;
                    }

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                }
            }

        }
    }

}

@media screen and (max-width: 875px) {
    .contact-view-comp1onent {

        .contact-container {
            width: 100%;
            grid-template-columns: 1fr;

            .contact-card {
                grid-column: 1;
            }
        }
    }
}

@media screen and (max-width: $mobile-bp) {
    .contact-view-component {
        .contact-container {

            grid-template-columns: 100%;
            width: 100%;
            padding: 10px;

            .contact-card {
                grid-column: 1;

                .details {
                    .detail-box {
                        padding-left: 20px;
                        padding-right: 10px;

                        .text {
                            font-size: 15px;
                            padding-left: 15px;
                            overflow-wrap: break-word;
                        }
                    }
                }
            }
        }
    }
}