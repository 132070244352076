@import 'commonStyles';
@import 'themeStyles';


.head-anchor{
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
    height: 0;
    width: 0;
}

@media screen and (max-width: $mobile-bp){
    .head-anchor{
        top:-30px;
    }
}