@import './commonStyles';
@import './themeStyles';

.why-component {
    // border: $dev-border;
    overflow: hidden;
    width: 95%;
    margin: $box-gaps 0;
    padding: $box-gaps 0;

    @include flex-column();
    background-image: linear-gradient(-20deg, rgb(255, 237, 216) 0%, #ebf9ff 100%);
    // background-color: black;
    box-shadow: $box-shadows;
    border-radius: 10px;
    position: relative;

    .why {
        // border: $dev-border;
        font-size: $title-font-size;
        text-align: center;
        margin: 20px 0;
    }

    .answer-container {
        // border: $dev-border;
        word-wrap: normal;
        position: relative;

        .al1 {
            // border: $dev-border;
            width: 60%;
            font-size: 1.8em;
            margin: 15px 0;
            margin-left: 10%;
        }

        .al2,
        .al3,
        .al4 {
            // border: $dev-border;
            font-size: 1.5em;
            width: 60%;
            margin-left: 10%;

            margin-top: 20px;
        }

        .al4 {
            margin-bottom: 40px;
            position: relative;

            .yt-link {
                text-decoration: none;
                color: black;
            }

            .youtube-icon {
                margin: 5px;
                margin-right: 10px;
                height: 56px;
                width: auto;
                cursor: pointer;
                float: left;
                border-radius: 10px;
                outline-color: red;
            }
        }
    }

    .rocket-icon {
        height: 80%;
        width: auto;
        position: absolute;
        right: 10%;
        top: 10%;
        visibility: hidden;
    }

    .rocket-animate {
        visibility: visible;
        animation: bottom-to-top 2s, vibrate 0.25s 0.5s linear infinite;
    }

    @keyframes vibrate {
        $vibration-degree: 1deg;

        0% {
            transform: rotateZ($vibration-degree);
        }

        50% {
            transform: rotateZ(-$vibration-degree);
        }

        100% {
            transform: rotateZ($vibration-degree);
        }
    }

    @keyframes bottom-to-top {
        0% {
            transform: scale(50%);
            top: 100%;
        }

        100% {
            transform: scale(100%);
            // top: 50%;
        }
    }

    .reason-container {
        // border: $dev-border;
        @include flex-center-row;

        .reason-block {
            // border: $dev-border;
            margin: 20px 10px;

            // .reason-head{

            // }

            .reason-text {
                word-wrap: normal;
            }
        }
    }

}

@media screen and (max-width: $tablet-bp) {
    .why-component {
        .why {
            margin-top: 10px;
        }

        .answer-container {
            // border: 1px solid black;
            width: 75%;
            margin: 0;
            padding: 0 20px;
            margin-left: 30px;

            .al1,
            .al2,
            .al3,
            .al4 {
                width: 100%;
                margin-left: 0;
            }

            .al4 {
                margin-bottom: 10px;
            }
        }

        .rocket-icon {
            height: 60%;
            right: 5%;
            top: 25%;
        }
    }
}

@media screen and (max-width: $mobile-bp) {
    .why-component {

        // border: $dev-border;
        // * {
        //     // border: $dev-border;
        // }

        width: 95%;
        @include flex-center-column();

        .why {
            font-size: $mobile-title-font-size - 6px;
        }

        .answer-container {
            width: 100%;
            margin-left: 0;

            .al1,
            .al2,
            .al3,
            .al4 {
                margin-left: 0;
                // padding: 0 20px;
                width: 100%;
            }

            .al1 {
                font-size: 1.55em;
            }

            .al2,
            .al3,
            .al4 {
                font-size: 1.45em;
                white-space: pre-wrap;
            }
        }

        .rocket-icon {
            display: none;
        }
    }
}