/*
* This is common styles container file
* it will be imported as row file
*/
@use "sass:color";
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,300&display=swap');


@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin flex-center {
    justify-content: center;
    align-items: center;
}


@mixin flex-center-row {
    @include flex-row();
    @include flex-center();
}

@mixin flex-center-column {
    @include flex-column();
    @include flex-center();
}

@mixin flex-space-between-row {
    @include flex-row();
    justify-content: space-between;
    align-items: center;
}



$small-screen-width : 400px;
$medium-screen-width : 1200px;

$desktop-bp: 1440px;
$laptop-bp: 1024px;
$tablet-bp: 768px;
$mobile-bp: 425px;


$dev-border: 2px solid black;

$blue-back-color: rgb(0, 158, 224);
$green-back-color: rgb(0, 167, 59);

.gray-font {
    color: rgb(100, 100, 100);
}

.blue-font {
    color: $blue-back-color;
}

// Generic for material buttons
@mixin outline-ripple {
    outline-style: solid;
    outline-width: 2px;
    animation: button-expand-outline 2s linear 0s infinite;
}

@mixin material-button($color) {
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    color: white;
    background-color: $color;
    font-size: 1.2em;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    // To create blue rippling outline
    &.outline-ripple {
        outline-color: $color;
        @include outline-ripple;
    }

    &.do-shadow {
        box-shadow: 0 0px 20px 0 color.adjust($color, $whiteness: 10%);
    }

    &:hover {
        background-color: color.adjust($color, $whiteness: 20%);
    }
}

@keyframes button-expand-outline {
    0% {
        outline-offset: 2px;
    }

    15% {
        outline-offset: 10px;
        outline-color: transparent;
        outline-width: 0;
    }

    100% {
        outline-offset: 10px;
        outline-color: transparent;
        outline-width: 0px;
    }
}

// This will set 2px solid outline and create rippling outline
.outline-ripple {
    @include outline-ripple;
}

.blue-button {
    @include material-button($blue-back-color);
}

.green-button {
    @include material-button($green-back-color);
}

.dull-blue-button {
    @include material-button(rgb(138, 178, 195));
}