@use "sass:color";
@import 'commonStyles';
@import 'themeStyles';

$nav-container-height: 80px;

.nav-container {
    position: fixed;
    top: 0;
    z-index: 2;
    height: $nav-container-height;
    width: 100%;

    background: color.adjust($navbar-color, $alpha: -0.50);
    border: 1px solid color.adjust($navbar-color, $alpha: -0.25);
    border-top: none;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(45px);

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: $nav-container-height;
    align-items: center;

    .site-signature {
        // border: 2px solid rgb(255, 10, 10);
        @include flex-center-row();
        justify-self: start;
        height: 60%;
        padding-left: 20px;
        padding-right: 10px;

        .site-logo-div {
            // border: 2px solid rgb(0, 17, 255);
            height: 100%;
            @include flex-center-row();
            padding-left: 10px;
            padding-right: 5px;

            .site-logo {
                height: 100%;
                border-radius: 10px;
                border-top-right-radius: 0px;

                // background-color: white;
                // border: 2px solid red;
            }
        }

        .logo-text {
            // border: 2px solid rgb(255, 255, 255);
            height: 100%;

            .site-logo-text {
                height: 100%;
            }
        }
    }

    .navbar {
        // border: 2px solid red;
        // background-color: yellow;

        grid-column: 2/4;
        justify-self: end;
        height: 100%;
        @include flex-center-row;
        margin-right: 50px;

        .navbar-item {
            text-decoration: none;
            // border: 2px solid black;
            background-color: transparent;
            color: $blue-back-color;
            border: none;
            height: 100%;
            @include flex-center-row;
            margin-right: 2px;
            padding-left: 12px;
            padding-right: 12px;
            font-size: 1.2em;

            &:hover {
                background-color: rgba(129, 129, 129, 0.11);
                cursor: pointer;
            }
        }

    }

    .section-name {
        justify-self: center;
        font-size: $mobile-title-font-size;
        color: $blue-back-color;
        grid-column: 2/3;
    }
}

// tablet
@media screen and (max-width: $tablet-bp) {
    .nav-container {
        width: 100%;
        grid-template-columns: repeat(1, 1fr);

        .section-name {
            font-size: 1.6em;
            color: $blue-back-color;
            grid-column: 2/3;
            text-align: center;
            height: 100%;
            grid-column: 1/2;
            width: 100%;
            justify-content: start;

            span {
                padding: 0 20px 5px 20px;
                border-radius: 0 0 10px 10px;
                background: color.adjust($navbar-color, $whiteness: 10%);
                backdrop-filter: blur(45px);
                box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
            }
        }

        $navigator-size: 30px;
        $arrow-size: 50%;
        $navigator-background: $blue-back-color;
        $navigator-arrow-color: white;

        $arrow-thickness: 15%;

        &::before {
            // border: $dev-border;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 2;
            // background-color: rgba($header-background-color, 100%);
        }

        .site-signature {

            box-sizing: border-box;
            position: relative;
            grid-column: 1;
            justify-self: center;
            z-index: 3;
            padding: 4px 0;
            width: 100%;

            .site-logo-div {
                padding: 0;
                margin-right: 5px;
            }

            .logo-text {
                margin-left: 5px;
            }

        }

        .navbar .mobile-navigator {


            height: $navigator-size;
            width: $navigator-size;

            background: $navigator-background;
            position: absolute;

            top: 100%;

            @include flex-center-row;

            border-radius: 0 0 50% 50%;

            .navigator-arrow {
                background-color: $navigator-arrow-color;
                height: $arrow-size;
                width: $arrow-size;
                transform-origin: 0 25%;
                position: absolute;
                top: 35%;
                clip-path: polygon(0 0,
                        50% 50%,
                        100% 0,
                        100% 0+$arrow-thickness,
                        50% 50%+$arrow-thickness,
                        0 0+$arrow-thickness );

                transition-duration: 500ms;
            }

            .inverse-arrow {
                transform: rotateX(180deg);
                transition-duration: 500ms;
            }
        }

        .navbar {
            position: absolute;
            z-index: 1;
            top: 100%;
            flex-direction: column;
            background-color: $button-background-color;
            height: initial;
            width: 100vw;
            margin: initial;
            background-color: rgba(255, 255, 255, 0.98);

            .navbar-item {
                border: 1px solid $header-border-color;
                width: 100vw;
                height: 70px;

                transition-duration: 400ms;
            }

            .hide-navbar {
                overflow: hidden;
                border: none;
                color: transparent;
                height: 0;
                transition-duration: 400ms;
            }
        }

    }
}

;