@use "sass:color";
@import 'commonStyles';
@import 'themeStyles';

.home-component {
    // border: $dev-border;
    box-shadow: $box-shadows;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    margin-bottom: $box-gaps;

    .swiper {
        width: 100%;
        height: 100%;
    }

    .img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .home-slide {
        height: 100%;
        width: 100%;
        background-image: linear-gradient(174.2deg, rgba(255, 244, 228, 1) 7.1%, rgba(240, 246, 238, 1) 67.4%);

        display: flex;
        align-items: center;
    }

    .slide-1 {

        .pankaj-img {
            position: absolute;
            right: 80px;
            bottom: -5px;
            width: 30%;
        }

        .info-block {
            // border: 1px solid black;
            display: inline-block;
            padding-left: 100px;

            .welcome {
                text-align: center;
                text-transform: uppercase;
                font-size: 1.4em;
            }

            h1 {
                padding-top: 10px;
                font-size: 2.5em;
            }

            .name {
                padding-top: 80px;
                font-size: 1.5em;
            }

            .line {
                width: 600px;
                font-size: 1.4em;
                padding-top: 20px;
                color: rgb(83, 83, 83);
                font-size: 1.25em;

                .quote {
                    font-size: 2em;
                    padding: 0 10px;
                }
            }

            .slide-1-button {
                margin-top: 30px;
            }
        }
    }

    .slide-2 {
        background-image: linear-gradient(-174.2deg, rgba(255, 244, 228, 1) 7.1%, rgba(240, 246, 238, 1) 67.4%);
        overflow: hidden;

        .hospital-img {
            position: absolute;
            left: 0px;
            bottom: -5px;
            height: 150%;
        }

        .visit-container {
            display: flex;
            flex-direction: column;
            width: 20%;
            position: absolute;
            right: 10%;
            padding: 20px;
            border-radius: 10px;
            border: 2px solid $blue-back-color;
            backdrop-filter: blur(10px);
            background: color.adjust($navbar-color, $alpha: -0.50);
            outline-color: $blue-back-color;

            ._24x7-text {
                display: block;
                width: 100%;
            }

            .slide-2-button {
                margin-top: 30px;
            }
        }
    }
}


@media screen and (max-width: $tablet-bp) {
    .home-component {
        width: 100%;

        .slide-1 {
            align-items: flex-start;
            padding-top: 140px;

            .info-block {
                padding: 0;
                width: 100%;

                p,
                h1 {
                    text-align: center;
                }

                .name {
                    padding-top: 50px;
                    padding-left: 40px;
                }

                .line {
                    width: 50%;
                    line-height: 1.8em;
                    padding-left: 40px;
                    text-align: left;
                    font-size: 1.2em;

                    .quote:nth-child(1) {
                        padding-left: 0px;
                    }

                    .quote {
                        font-size: 1.5em;
                    }
                }

                .slide-1-button {
                    margin-left: 40px;
                }
            }

            .pankaj-img {
                width: 35%;
            }
        }

        .slide-2 {
            .visit-container {
                position: absolute;
                width: 35%;
                top: 30%;
                right: 10%;
            }
        }
    }
}

@media screen and (max-width: $mobile-bp) {
    .home-component {

        .slide-1 {
            padding-top: 120px;

            .info-block {
                z-index: 4;

                .welcome {
                    text-align: left;
                }

                p,
                h1 {
                    font-size: 2em;
                    padding-left: 40px;
                    padding-right: 40px;
                    text-align: left;
                }

                .name {
                    padding-top: 20px;
                }

                .line {
                    width: 100%;
                    padding-right: 40px;
                }
            }

            .pankaj-img {
                width: 70%;
                right: -40px;
                z-index: 1;
            }
        }

        .slide-2 {
            .hospital-img {
                height: 100%;
            }

            .visit-container {
                position: absolute;
                width: 60%;
                // top: 35%;
                top: 20%;
                // bottom: 10px;
                right: 5%;
            }
        }
    }
}