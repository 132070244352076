@use "sass:color";
@import 'themeStyles';
@import 'commonStyles';

.testimonial-component {
    // border: $dev-border;
    width: 100%;
    margin: $box-gaps 0 2*$box-gaps 0;
    padding: $box-gaps 2.5%;
    background-image: linear-gradient(45deg, rgba(255, 244, 228, 1) 7.1%, rgba(240, 246, 238, 1) 67.4%);

    .component-title {
        // border: 2px solid red;
        text-align: center;
        margin: $title-margin;
        font-size: $title-font-size;
    }

    .testimonial-container {
        // border: 2px solid green;

        .testimonial-block {
            margin-bottom: 25px;
            padding: 20px;
            border: 2px solid $box-border-color;
            border-radius: 15px;
            box-shadow: $box-shadows;
            overflow: hidden;

            $color1 : #ebf9ff;
            $color2: rgba(212, 255, 221, 0.152);

            &:nth-child(odd) {
                background: linear-gradient(92.29deg, $color1 50%, $color2 100%);
            }

            &:nth-child(even) {
                background: linear-gradient(267.71deg, $color1 50%, $color2 100%);
            }

            .block-head {
                $block-head-font-size: 25px;
                @include flex-row();
                align-items: center;
                position: relative;
                padding: 0 20px 10px 20px;

                // border: 2px solid blueviolet;
                .user-name {
                    height: 100%;
                    @include flex-center-row;
                    // border: 2px solid black;
                    font-size: (($block-head-font-size)-5px);
                    font-weight: 1000;
                }

                .stars-group {
                    margin-left: 15px;
                    @include flex-center-row();
                    position: relative;

                    .star {
                        height: 1.5em;
                        margin-right: 5px;
                    }
                }
            }

            .block-text {
                // border: 2px solid black;
                padding: 0 20px;
                text-align: justify;
                font-size: 1.2em;
            }
        }
    }

    .button-container {
        @include flex-center-row;
        position: relative;

        .review-button {
            position: absolute;
            right: 0;
        }

        .review-button,
        .more-button {
            font-size: 1.4em;
        }

        .button:hover {
            background-color: darken($button-background-color, 5);
            cursor: pointer;
            transition-duration: 0s;
        }

        .button:active {
            background-color: lighten($button-background-color, 100);
        }
    }
}

@media screen and (max-width: $mobile-bp) {
    .testimonial-component {

        // &, *{
        //     border: $dev-border;
        // }

        width: 100vw;

        .component-title {
            font-size: $mobile-title-font-size;
        }

        .testimonial-container {
            .testimonial-block {
                .block-head {
                    @include flex-column;
                    align-items: flex-start;
                    margin-bottom: 10px;
                    overflow: hidden;

                    .user-name {
                        font-size: 22px;
                    }

                    .stars-group {
                        margin-left: 0;
                        margin-top: 5px;
                    }
                }

                .block-text {
                    margin-left: 0;
                    font-size: 16px;
                }

            }

        }

        .button-container {

            @include flex-center-row;

            .review-button {
                position: initial;
            }

            .more-button,
            .review-button {
                font-size: 1.1em;
                margin-left: 10px;
                margin-right: 10px;
                height: 2.75em;
                width: 50%;
                padding: 0;
                flex-grow: 1;
            }
        }
    }
}