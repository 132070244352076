@use "sass:color";
@import 'commonStyles';
@import 'themeStyles';


.team-component {
    // border: $dev-border;

    width: 100%;
    background: color.adjust(rgb(255, 237, 216), $alpha: -0.6); // background: #0d97d2;
    border: 1px solid color.adjust(rgb(255, 237, 216), $lightness: 5);
    border-right: none;
    border-left: none;

    @include flex-center-column;
    margin-top: $box-gaps;
    padding: $box-gaps 0;

    .component-title {
        // border: 2px solid red;
        text-align: center;
        margin: $title-margin;
        margin-bottom: 0;
        font-size: $title-font-size;
    }

    .member-container {
        // border: 2px solid red;
        width: 95%;
        padding: 20px;
        padding-bottom: 30px;
        padding-top: 10px;

        .member-card {
            min-height: 250px;
            max-height: 500px;
            overflow: hidden;
            @include flex-column;
            align-items: center;
            border-radius: 15px;
            box-shadow: $box-shadows;
            background: linear-gradient(180deg, #ebf9ff 23.23%, rgba(231, 231, 231, 0) 100%);
            border: 1px solid #A3A3A3;
            margin: 20px 0;
            cursor: pointer;

            .img-div {
                border: 0.5px solid $box-border-color;
                // height: 600px;

                width: 85%;
                aspect-ratio: 1/1;
                border-radius: 10px;
                overflow: hidden;
                margin: 20px;
                box-shadow: $box-shadows;

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .name {
                // border: $dev-border;
                width: 100%;
                padding: 5px;
                padding-left: 25px;
                // text-align: center;
                font-size: 1.2em;
                font-weight: 600;
                color: rgb(0, 158, 224);
            }

            .degree {
                // border: $dev-border;
                width: 100%;
                padding: 5px;
                padding-left: 25px;
                margin-bottom: 10px;
                word-wrap: break-word;
            }
        }
    }

    .button {
        height: 3em;
        font-size: 20px;
        font-weight: 600;
        padding: 10px 30px 10px 30px;
        background-color: $button-background-color;
        border-radius: 50px;
        border: 1px solid $button-border-color;
        box-shadow: $box-shadows;
        margin-top: 20px;

        span {
            line-height: 10px;
            font-weight: bolder;
            font-size: 25px;
            height: 10px;
            padding: 0;
        }
    }
}

@media screen and (max-width: $mobile-bp) {
    .button {
        span {
            font-size: 40px;
        }
    }

}