.ripple-button {
    width: 220px;
    height: 120px;
    background: rgb(122, 95, 95);
    border-radius: 10px;

    overflow: hidden;
    position: relative;
}

span.ripple {
    position: absolute;
    /* The absolute position we mentioned earlier */
    border-radius: 50%;
    transform: scale(0);
    background-color: rgba(255, 255, 255, 0.4);
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}