@import 'commonStyles';
@import 'themeStyles';

.treatment-result-component {
    // border: $dev-border;

    @include flex-center-column;
    width: 100%;
    height: auto;
    min-height: 100px;
    margin: $box-gaps 0;

    .component-title {
        // border: 2px solid red;
        text-align: center;
        margin: $title-margin;
        font-size: $title-font-size;
    }

    .result-container {
        // border: $dev-border;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 35vh;
        position: relative;
        margin-bottom: 20px;
        gap: 35px;
        width: 95%;

        .result-card {
            width: 100%;
            height: 100%;
            justify-self: center;
            align-self: center;
            overflow: hidden;
            border: 1px solid $box-border-color;
            border-radius: 10px;
            box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.25), 5px 5px 5px 0px rgba(0, 0, 0, 0.125);
            background-color: black;
            box-shadow: $box-shadows;

            &:hover {
                cursor: pointer;
                transform: scale(104%, 104%);
            }

            .result-image {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 5px;
            }
        }
    }

    .more-button {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        font-size: 1.2em;
        height: 2.75em;
        width: 10%;
    }
}


@media screen and (max-width: $tablet-bp) {
    .treatment-result-component {

        .result-container {
            grid-template-columns: repeat(2, 1fr);
            // .result-card{

            // }
        }

        .more-button {
            width: 25%;
        }
    }
}

@media screen and (max-width: 500px) {
    .treatment-result-component {
        .result-container {
            grid-template-columns: repeat(1, 1fr);
        }

        .more-button {
            width: 50%;
        }
    }
}

@media screen and (max-width: $mobile-bp) {
    .treatment-result-component {

        .component-title {
            font-size: $mobile-title-font-size;
        }
    }
}